<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card>
      <div class="mb-3 w-100 d-flex align-items-center">
        <h1>Representative List</h1>
      </div>
      <b-row>
        <b-col cols="12">
          <b-table
            class="position-relative"
            :items="representativesList"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="No items available"
          >
            <template #cell(createDate)="data">
              {{ new Date(data.item.createDate).toLocaleDateString("fa-IR") }}
            </template>
            <template #cell(actions)="data">
              <div class="d-flex align-items-center" style="gap: 1rem">
                <b-button
                  variant="transparent"
                  size="sm"
                  @click="renderEditModal(data.item)"
                >
                  <feather-icon
                    size="20"
                    icon="EditIcon"
                    class="text-primary"
                  />
                </b-button>
                <b-button
                  variant="transparent"
                  size="sm"
                  @click="renderDeleteModal(data.item)"
                >
                  <feather-icon
                    size="20"
                    icon="TrashIcon"
                    class="text-danger"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
        <b-col cols="12">
          <!-- pagination -->
          <div class="my-2 d-flex align-items-center justify-content-center">
            <b-pagination
              v-model="pageNumber"
              :total-rows="totalCount"
              :per-page="count"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <!-- Delete Modal Start -->
    <b-modal
      id="modal-delete"
      centered
      ok-variant="danger"
      title="Delete A Representative"
      ok-title="Delete"
      cancelTitle="Dismiss"
      @ok="deleteRepresentative"
    >
      <template v-if="tempRepresentative">
        <span>
          Are you sure you want to delete "{{ tempRepresentative.name }}" ?
        </span>
      </template>
    </b-modal>
    <!-- Delete Modal End -->
    <!-- Edit Modal Start -->
    <b-modal
      id="modal-edit"
      centered
      size="lg"
      ok-variant="success"
      title="Edit a representative"
      ok-title="Update"
      cancelTitle="Dismiss"
      @ok="updateRepresentative"
    >
      <template v-if="tempRepresentative">
        <b-container fluid>
          <b-form-row>
            <b-col cols="12" md="6">
              <b-form @submit.prevent>
                <b-form-group label="Representative name" label-for="name">
                  <b-form-input
                    id="name"
                    trim
                    placeholder="Enter representative name"
                    v-model="tempRepresentative.name"
                  />
                </b-form-group>
              </b-form>
            </b-col>
            <b-col cols="12" md="6">
              <b-form @submit.prevent>
                <b-form-group label="Owner name" label-for="ownerName">
                  <b-form-input
                    id="ownerName"
                    trim
                    placeholder="Enter owner name"
                    v-model="tempRepresentative.ownerName"
                  />
                </b-form-group>
              </b-form>
            </b-col>
            <b-col cols="12" md="6">
              <b-form @submit.prevent>
                <b-form-group
                  label="Representative Phonenumber"
                  label-for="phone"
                >
                  <b-form-input
                    id="phone"
                    trim
                    placeholder="Enter representative Phonenumber"
                    v-model="tempRepresentative.phone"
                  />
                </b-form-group>
              </b-form>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label-for="state" label="Representative City">
                <b-form-select
                  id="state"
                  v-model="tempRepresentative.state"
                  :options="stateOptions"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Representative Address" label-for="address">
                <b-form-textarea
                  id="address"
                  v-model="tempRepresentative.address"
                  rows="2"
                  placeholder="Enter representative address"
                />
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-container>
      </template>
    </b-modal>
    <!-- Edit Modal End -->
  </b-overlay>
</template>
<script>
export default {
  title: "Representative List",
  data() {
    return {
      isLoading: false,
      count: 10,
      pageNumber: 1,
      totalCount: null,
      representativesList: null,
      myTableColumns: [
        {
          key: "representativeId",
          label: "Representative ID",
        },
        {
          key: "name",
          label: "Representative Name",
        },
        {
          key: "ownerName",
          label: "Owner Name",
        },
        {
          key: "phone",
          label: "Representative Phonenumber",
        },
        {
          key: "address",
          label: "Representative Address",
        },
        {
          key: "createDate",
          label: "Create Date",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      stateOptions: [
        {
          value: null,
          text: "Choose an option",
        },
        {
          value: 1,
          text: "Tehran",
        },
        {
          value: 2,
          text: "Other Cities",
        },
      ],
      tempRepresentative: null,
    };
  },
  watch: {
    pageNumber: {
      handler(val) {
        this.getAllRepresentatives();
      },
    },
  },
  mounted() {
    this.getAllRepresentatives();
  },
  methods: {
    async updateRepresentative() {
      try {
        this.isLoading = true;
        let _this = this;
        let updateARepresentative = new UpdateARepresentative(_this);
        updateARepresentative.setRequestParamDataObj(_this.tempRepresentative);
        await updateARepresentative.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Operation Was Succesful",
                icon: "CheckIcon",
                variant: "success",
                text: "Representative Updated Succesfully",
              },
            });
            _this.getAllRepresentatives();
            _this.tempRepresentative = null;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async deleteRepresentative() {
      try {
        this.isLoading = true;
        let _this = this;
        let deleteARepresentative = new DeleteARepresentative(_this);
        deleteARepresentative.setRequestParam({
          id: _this.tempRepresentative.representativeId,
        });
        await deleteARepresentative.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Operation Was Succesful",
                icon: "CheckIcon",
                variant: "success",
                text: "Representative Deleted Succesfully",
              },
            });
            _this.tempRepresentative = null;
            _this.getAllRepresentatives();
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    renderDeleteModal(representative) {
      this.tempRepresentative = JSON.parse(JSON.stringify(representative));
      this.$bvModal.show("modal-delete");
    },
    renderEditModal(representative) {
      this.tempRepresentative = JSON.parse(JSON.stringify(representative));
      this.$bvModal.show("modal-edit");
    },
    async getAllRepresentatives() {
      try {
        this.isLoading = true;
        let reqParams = {
          count: this.count,
          pageNumber: this.pageNumber,
        };
        let _this = this;
        let getAllRepresentatives = new GetAllRepresentatives(_this);
        getAllRepresentatives.setRequestParam(reqParams);
        await getAllRepresentatives.fetch((response) => {
          if (response.isSuccess) {
            _this.representativesList = response.data.representatives;
            _this.totalCount = response.data.representativesCount;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BContainer,
    BPagination,
    BFormRow,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BFormTextarea,
    BOverlay,
    BFormSelect,
    BModal,
  },
};
import {
  GetAllRepresentatives,
  DeleteARepresentative,
  UpdateARepresentative,
} from "@/libs/Api/Representative";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
  BContainer,
  BPagination,
  BFormRow,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BTable,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormTextarea,
  BOverlay,
  BFormSelect,
  BModal,
} from "bootstrap-vue";
</script>
